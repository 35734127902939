<script>
  /**
   * Promise related to the current popup opening.
   * The promise is set when the popup is opened, and it is resolved when
   * the popup is dismissed.
   * @type {Promise}
   */
  let promise = null

  /**
   * Resolver function of the upper-declared 'promise'.
   * @type {Function}
   */
  let resolver = null

  export default {
    data() {
      return {
        ModalMixin_registeredOnAction: false,
      }
    },
    computed: {
      /**
       * Name and params of the currently opened modal (i.e. modal referenced
       * in the URL)
       * @type {Object}
       */
      modal() {
        const { $route } = this

        if (!$route || !$route.query || !$route.query.modal) {
          return null
        }

        let { modal, params } = $route.query

        try {
          params =
            params && typeof params === 'string'
              ? JSON.parse(decodeURIComponent(params) || '{}')
              : params || {}
        } catch (e) {
          modal = null
          params = {}
        }

        return {
          name: decodeURIComponent(modal),
          params,
        }
      },
    },
    watch: {
      $route: {
        deep: true,
        immediate: true,
        handler(next, prev) {
          const {
            ModalMixin_registeredOnAction: registeredOnAction,
            dismissModal,
            onModalDismiss,
          } = this

          const willHaveModal = next && next.query && next.query.modal
          const hadModal = prev && prev.query && prev.query.modal

          //  =====  Once  =====

          // If there is no route info or query params, but there was before
          // and the 'promise' is still pending, it means 'dismissModal' was
          // not called, so we force it.
          if (!willHaveModal && hadModal && promise) {
            dismissModal('auto')
          }

          // A modal is intended to be opened (query params now but not before),
          // and there is no pending 'promise', it means 'openModal' was not
          // called, and the app straight opened on the modal. So, we force
          // a promise creation
          if (willHaveModal && !hadModal && !promise) {
            promise = new Promise(resolve => {
              resolver = resolve
            })
          }

          //  =====  For every components  =====

          // If a modal is opened (present in URL) and the component would
          // listen to its dismissal (i.e. has a onModalDismiss method declared)
          // we register it on the promise resolution.
          if (willHaveModal && onModalDismiss && !registeredOnAction) {
            this.ModalMixin_registeredOnAction = true

            promise.then(args => {
              if (onModalDismiss) {
                onModalDismiss(willHaveModal, ...args)
              }
            })
          }

          // When there is no modal anymore, unregister all registered
          // components
          if (!willHaveModal && registeredOnAction) {
            this.ModalMixin_registeredOnAction = false
          }
        },
      },
    },
    methods: {
      /**
       * Open the modal identified by the given 'name'
       * @param {String} selector
       * @param {Object} params
       * @returns {Promise}
       */
      openModal(selector, params) {
        const { $route, $router } = this

        // Vue component duck-typing
        // Extract the name from the modal component's name (trim the "-modal")
        const name =
          typeof selector === 'object' && selector.render && selector.name
            ? selector.name
            : selector

        promise = new Promise(resolve => {
          resolver = resolve
        })

        $router.replace({
          path: $route.path,
          query: {
            ...$route.query,
            modal: encodeURIComponent(name),
            params: params ? encodeURIComponent(JSON.stringify(params)) : undefined,
          },
        })

        return promise
      },
      /**
       * Dismiss the potential currently opened modal.
       * @returns {Promise}
       */
      dismissModal(reason, ...params) {
        const { $route, $router } = this

        // Call first to avoid the watcher re-call dismissModal.
        // i.e. Announce first that the modal is over.
        if (resolver) {
          resolver([reason, ...params])
        }

        resolver = null
        promise = null

        // Navigate to remove modal query params from the URL
        $router.replace({
          path: $route.path,
          query: {
            ...$route.query,
            modal: undefined,
            params: undefined,
          },
        })
      },
    },
  }
</script>
