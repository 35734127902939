import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/en'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import _keyBy from 'lodash/keyBy'

import { __ } from '@comet/i18n'

import Icon from '@/core/graphics/Icon/Icon'

import { screenPhoneMax, screenTabletMax, screenLaptopMax, screenDesktopMax } from './mq'
export { default as mq } from './mq'

/**
 * Generates a hashmap referencing the Comet icons, specifying for each
 * the Vue component to use to display it and its expected props.
 * @return {Object}
 */
function generateCometIconValues() {
  const names = require
    .context('../assets/images/icons')
    .keys()
    .map(path => path.substring(2).split('.')[0])

  const icons = names.map(name => ({
    component: Icon,
    props: { name, size: Icon.Size.SMALL },
  }))

  return _keyBy(icons, i => i.props.name)
}

/**
 * Our Vuetify custom configuration including themes, breakpoints, ...
 * @type {Object}
 */
export const VuetifyConfig = {
  theme: {
    themes: {
      light: {
        // Vuetify native colors
        // See : https://vuetifyjs.com/en/styles/colors/

        // Vuetify native themes
        primary: '#0044FF', // --color-blue-50
        secondary: '#73808D', // --color-grey-60
        accent: '#FFCC00', // --color-yellow-50
        success: '#11CC66', // --color-green-50
        error: '#EE0044', // --color-red-50
        warning: '#FF8800', // --color-orange-50

        // Custom themes
        brand: '#0044FF', // --color-blue-50
        positive: '#11CC66', // --color-green-50
        negative: '#EE0044', // --color-red-50
        'background-light': '#FFFFFF',
        background: '#F9FAFD',
        'background-dual': '#F4F6FC',
        border: '#E3E9F2',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: screenPhoneMax,
      sm: screenTabletMax,
      md: screenLaptopMax,
      lg: screenDesktopMax,
    },
  },
  lang: {
    locales: {
      fr,
      en,
    },
    current: 'fr',
    t: (key, ...params) => __(key, params),
  },
  icons: {
    iconfont: 'mdi',
    values: generateCometIconValues(),
  },
}

/**
 * Vuetify module instance.
 * When the Vuetify module is instanciated (createVuetify) we keep a reference on this
 * instance in order to provide direct access to useful functions like 'setLocale' from
 * anywhere.
 *
 * /!\ This module is not a singleton. Do no try to access this 'i18n' instance
 * from anywhere else in the app. Use instead the provided functions declared by
 * this module which are deliberately the only ones able to access this 'i18n' instance.
 */
let vuetify = null

/**
 * Create and configure the Vuetify store instance
 * @returns {Object}
 */
export default function createVuetify() {
  Vue.use(Vuetify)

  // Initialize Vuetify (components, themes, ...)
  vuetify = new Vuetify(VuetifyConfig)

  return vuetify
}

/**
 * Update the Vuetify module configuration with the new given 'language'
 * @param {String} locale
 * @param {String} language
 * @returns {void}
 */
export function setVuetifyLocale(locale, language) {
  if (vuetify) {
    vuetify.framework.lang.current = language
  }
}
