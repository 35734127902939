/**
 * Status of a match between a mission and a freelance
 */
const MatchStatus = {
  TEAM_MEMBER_VALIDATION: 'teamMemberValidation',
  TEAM_MEMBER_VALIDATION_CANCELLED: 'teamMemberValidationCancelled',
  TEAM_MEMBER_VALIDATION_DECLINED: 'teamMemberValidationDeclined',
  FREELANCE_APPLICATION: 'freelanceApplication',
  FREELANCE_PROPOSAL: 'freelanceProposal',
  FREELANCE_PROPOSAL_CANCELLED: 'freelanceProposalCancelled',
  FREELANCE_DECLINED: 'freelanceDeclined',
  FREELANCE_NOT_INTERESTED: 'freelanceNotInterested',
  TEAM_MEMBER_PRESELECTION: 'teamMemberPreselection',
  FREELANCE_APPLICATION_CANCELLED: 'freelanceApplicationCancelled',
  CORPORATE_VALIDATION: 'corporateValidation',
  CORPORATE_DECLINED: 'corporateDeclined',
  CORPORATE_DUPLICATED: 'corporateDuplicated',
  CORPORATE_CONFIRMED: 'corporateConfirmed',
  TEAM_MEMBER_CANCELLED: 'teamMemberCancelled',
}

/**
 * Mechanism used to create a MissionMatch (initiator entity or system)
 */
const MatchSource = {
  TEAM_MEMBER: 'teamMember',
  AUTOMATIC: 'automatic',
}

/**
 * Reasons given by a freelancer when he refuses a proposed mission during
 * the matching process (mission with a SEEKING status)
 */
const MatchFreelancerRefusal = {
  START_DATE: 'startDate',
  IS_ESN: 'isEsn',
  TJM: 'tjm',
  LOCATION: 'location',
  STACK: 'stack',
  RHYTHM: 'rhythm',
  DURATION: 'duration',
  COMPANY: 'company',
  OTHER: 'other',
  // New reasons:
  CANDIDATE_FREELANCE_OTHER_MISSION_FOUND: 'candidateFreelanceOtherMissionFound',
  PROCESS_FREELANCE_OTHER_MISSION_FOUND: 'processFreelanceOtherMissionFound',
  PROCESS_FREELANCE_INADEQUAT_MISSION: 'processFreelanceInadequatMission',
  INTERVIEW_FREELANCE_SOFT_SKILLS_WARNING: 'interviewFreelanceSoftSkillsWarning',
  INTERVIEW_FREELANCE_OTHER_MISSION_FOUND: 'interviewFreelanceOtherMissionFound',
  INTERVIEW_FREELANCE_INADEQUAT_MISSION: 'interviewFreelanceInadequatMission',
  CONFIRMED_FREELANCE_CANT_AGREE_ON_CONTRACT_TERMS: 'confirmedFreelanceCantAgreeOnContractTerms',
  CONFIRMED_FREELANCE_OTHER_MISSION_FOUND: 'confirmedFreelanceOtherMissionFound',
  PROPOSAL_FREELANCE_UNAVAILABLE: 'proposalFreelanceUnavailable',
  PROPOSAL_FREELANCE_DAILY_RATE_TOO_LOW: 'proposalFreelanceDailyRateTooLow',
  PROPOSAL_FREELANCE_INADAPTED_OFFER: 'proposalFreelanceInadaptedOffer',
  PROPOSAL_FREELANCE_INADAPTED_LOCATION: 'proposalFreelanceInadaptedLocation',
  PROPOSAL_FREELANCE_WONT_WORK_FOR_THIS_COMPANY: 'proposalFreelanceWontWorkForThisCompany',
  PROPOSAL_FREELANCE_INADAPTED_RYTHM: 'proposalFreelanceInadaptedRythm',
  PROPOSAL_FREELANCE_NOT_ENOUGH_REMOTE: 'proposalFreelanceNotEnoughRemote',
  CANDIDATE_FREELANCE_NO_MORE_INTERESTED: 'candidateFreelanceNoMoreInterested',
  PROCESS_FREELANCE_NO_MORE_INTERESTED: 'processFreelanceNoMoreInterested',
  INTERVIEW_FREELANCE_NO_MORE_INTERESTED: 'interviewFreelanceNoMoreInterested',
}

/**
 * Arguments given to the corporate when suggesting a freelancer during the
 * meeting process explaining why this freelancer fits the needs
 */
const MatchArgument = {
  SKILLS_LEVEL: 'levelInSkills',
  AVAILABILITY: 'availability',
  RETRIBUTION: 'retribution',
  LOCATION: 'location',
  DAY_PER_WEEK: 'daysPerWeek',
  INTEREST_SKILLS: 'interestInSkills',
  COMPANY_SIZE: 'companySize',
  DURATION: 'duration',
}

/**
 * Match status for freelancer
 * @type {Object}
 */
const MatchFreelancerStatus = {
  NEW: 'new',
  CANDIDAT: 'candidat',
  PRESENTATION: 'presentation',
  AVAILABILITY: 'availabilty',
  DATE_PICKING: 'datePicking',
  MEETING_SET: 'meetingSet',
  MEETING_DONE: 'meetingDone',
  DEAL_CREATED: 'dealCreated',
  FREE_ESTIMATE: 'freeEstimate',
  CONTRACT_CREATION: 'contractCreation',
  SIGNING: 'signing',
  READY: 'ready',
  ON_GOING: 'onGoing',
  FINISHED: 'finished',
  REFUSED_BY_FREELANCE: 'refusedByFreelance',
  REFUSED: 'refused',
  FAILED: 'failed',
}

/**
 * Available order types
 */
const MatchOrder = {
  CREATED_AT: 'createdAt',
  TITLE: 'title',
  START_DATE: 'startDate',
  LOCATION: 'location',
  RETRIBUTION: 'retribution',
  DAYS_PER_WEEK: 'daysPerWeek',
  DURATION_IN_DAYS: 'durationInDays',
  EXPERIENCE_LEVEL: 'experienceLevel',
}

/**
 * Source of cancel of a match
 * @type {Object}
 */
const MatchCancelSource = {
  FREELANCE: 'freelance',
  CORPORATE: 'corporate',
  TEAM_MEMBER: 'teamMember',
}

/**
 * Refusal reason of a match by a corporate
 * @type {Object}
 */
const MatchCorporateRefusalReason = {
  RETRIBUTION: 'retribution',
  SENIORITY: 'seniority',
  PASTWORK: 'pastWork',
  AVAILABILITY: 'availability',
  SKILLS: 'skills',
  LOCATION: 'location',
  INCOMPLETEPROFILE: 'incompleteProfile',
  OTHER: 'other',
  SOCIALSKILLS: 'socialSkills',
  NO_SHOW: 'noShow',
  MOTIVATION: 'motivation',
  // New reasons:
  PROCESS_CORPO_EXCESSIVE_PRICE: 'processCorpoExcessivePrice',
  PROCESS_CORPO_LOCATION: 'processCorpoLocation',
  PROCESS_CORPO_MOBILITY_ISSUE: 'processCorpoMobilityIssue',
  PROCESS_CORPO_MISSING_KEY_SKILL: 'processCorpoMissingKeySkill',
  PROCESS_CORPO_ALREADY_KNOWN_SOLUTION: 'processCorpoAlreadyKnownSolution',
  INTERVIEW_CORPO_EXCESSIVE_PRICE: 'interviewCorpoExcessivePrice',
  INTERVIEW_CORPO_LOCATION: 'interviewCorpoLocation',
  INTERVIEW_CORPO_MOBILITY_ISSUE: 'interviewCorpoMobilityIssue',
  INTERVIEW_CORPO_MISSING_KEY_SKILL: 'interviewCorpoMissingKeySkill',
  INTERVIEW_CORPO_ALREADY_KNOWN_SOLUTION: 'interviewCorpoAlreadyKnownSolution',
  INTERVIEW_CORPO_SOFT_SKILLS_WARNING: 'interviewCorpoSoftSkillsWarning',
  PROCESS_CORPO_LACK_OF_SENIORITY: 'processCorpoLackOfSeniority',
  INTERVIEW_CORPO_LACK_OF_SENIORITY: 'interviewCorpoLackOfSeniority',
}

/**
 * Refusal reason of a match by a team member
 * @type {Object}
 */
const MatchTeamMemberRefusalReason = {
  RETRIBUTION: 'retribution',
  SENIORITY: 'seniority',
  PASTWORK: 'pastWork',
  AVAILABILITY: 'availability',
  SKILLS: 'skills',
  LOCATION: 'location',
  INCOMPLETEPROFILE: 'incompleteProfile',
  OTHER: 'other',
  OTHER_MISSION: 'otherMission',
  SOCIALSKILLS: 'socialSkills',
  NO_SHOW: 'noShow',
  MOTIVATION: 'motivation',
  // New reasons:
  CANDIDATE_COMET_EXCESSIVE_PRICE: 'candidateCometExcessivePrice',
  CANDIDATE_COMET_LACK_OF_SENIORITY: 'candidateCometLackOfSeniority',
  CANDIDATE_COMET_MISSING_KEY_SKILL: 'candidateCometMissingKeySkill',
  CANDIDATE_COMET_LOCATION_ISSUE: 'candidateCometLocationIssue',
  CANDIDATE_COMET_MOBILITY_ISSUE: 'candidateCometMobilityIssue',
  CANDIDATE_COMET_CANT_START_EARLY_ENOUGH: 'candidateCometCantStartEarlyEnough',
  CANDIDATE_COMET_SOFT_SKILLS_WARNING: 'candidateCometSoftSkillsWarning',
  CONFIRMED_COMET_IMPOSSIBLE_TO_CONTRACT: 'confirmedCometImpossibleToContract',
  CONFIRMED_COMET_CANT_AGREE_ON_CONTRACT_TERMS: 'confirmedCometCantAgreeOnContractTerms',
  PROPOSAL_COMET_MISSION_OFFER_CANCELED: 'proposalCometMissionOfferCanceled',
}

/**
 * Refusal reason of a match by a freelancer
 * @type {Object}
 */
const PostMeetingFreelancerReason = {
  RETRIBUTION: 'retribution',
  LOCATION: 'location',
  TECHNICAL_CONTENT: 'technicalContent',
  CONDITIONS: 'conditions',
  OTHER_MISSION: 'otherMission',
  OTHER: 'other',
}

/**
 * Source of the pre-matching
 * @type {Object}
 */
const PreMatchingSource = {
  MATCHING_ENGINE: 'matchingEngine',
  MATCHING_V2: 'matchingV2',
  PERFECT_MATCH: 'perfectMatch',
  EXTENDED_MATCH: 'extendedMatch',
  TEAM_MEMBER_ACTION: 'teamMemberAction',
  TELESCOPE: 'telescope',
  FREELANCER_ACTION: 'freelancerAction',
  EXTERNAL_ACQUISITION: 'externalAcquisition',
  MATCHING_V4_0: 'matchingV4_0',
  MATCHING_V4_1: 'matchingV4_1',
  FREELANCER_AGENT: 'freelancerAgent',
}

/**
 * Root MatchTypes object
 */
const MatchTypes = {
  MatchArgument,
  MatchCancelSource,
  MatchCorporateRefusalReason,
  MatchTeamMemberRefusalReason,
  MatchFreelancerRefusal,
  MatchFreelancerStatus,
  MatchSource,
  MatchStatus,
  MatchOrder,
  PostMeetingFreelancerReason,
  PreMatchingSource,
}

export default MatchTypes
